const binding = { modules: {}, dataActions: {} };

    const registerActionId = (actionPath) => {
        if (binding.dataActions[actionPath] &&
            binding.dataActions[actionPath].default &&
            binding.dataActions[actionPath].default.prototype &&
            binding.dataActions[actionPath].default.prototype.id) {
            binding.dataActions[binding.dataActions[actionPath].default.prototype.id] = binding.dataActions[actionPath];
        } else {
            Object.keys(binding.dataActions[actionPath] || {}).forEach(exportName => {
                if (binding.dataActions[actionPath][exportName] &&
                    binding.dataActions[actionPath][exportName].prototype &&
                    binding.dataActions[actionPath][exportName].prototype.Action) {
                    binding.dataActions[binding.dataActions[actionPath][exportName].prototype.id] = binding.dataActions[actionPath][exportName];
                }
            })
        }
    };

    const registerSanitizedActionPath = (sanitizedActionPath, dataAction) => {
        if (process.env.NODE_ENV === 'development') {
            if (!dataAction.default) {
                throw new Error('Data action path does not have a default export');
            }
            if (!(dataAction.default.prototype.id && binding.dataActions[dataAction.default.prototype.id]) || !binding.dataActions[sanitizedActionPath]) {
                binding.dataActions[sanitizedActionPath] = dataAction;
            }
        } else {
            binding.dataActions[sanitizedActionPath] = dataAction;
            if (!binding.dataActions[sanitizedActionPath].default) {
                throw new Error('Data action path ' + sanitizedActionPath + ' does not have a default export');
            }
            binding.dataActions[sanitizedActionPath].default.prototype.RegistrationId = sanitizedActionPath;
            if (binding.dataActions[sanitizedActionPath].default.prototype.id) {
                binding.dataActions[binding.dataActions[sanitizedActionPath].default.prototype.id] = sanitizedActionPath;
            }
        }
    };
    

        {   
            const actionPath = 'actions/create-order-for-payment.action';
            binding.dataActions[actionPath] = require('partner/actions/create-order-for-payment.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-categories.action';
            binding.dataActions[actionPath] = require('partner/actions/get-categories.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-category-details.action';
            binding.dataActions[actionPath] = require('partner/actions/get-category-details.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-crm-category-ids-for-tefanawwal.action';
            binding.dataActions[actionPath] = require('partner/actions/get-crm-category-ids-for-tefanawwal.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-crm-existing-category-record-for-tefanawwal.action';
            binding.dataActions[actionPath] = require('partner/actions/get-crm-existing-category-record-for-tefanawwal.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-crm-nationality-ids-for-tefanawwal.action';
            binding.dataActions[actionPath] = require('partner/actions/get-crm-nationality-ids-for-tefanawwal.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-crm-token-for-tefan-awaal-category.action';
            binding.dataActions[actionPath] = require('partner/actions/get-crm-token-for-tefan-awaal-category.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-crm-token.action';
            binding.dataActions[actionPath] = require('partner/actions/get-crm-token.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-existing-contact.action';
            binding.dataActions[actionPath] = require('partner/actions/get-existing-contact.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-fatoorah-payment-state.action';
            binding.dataActions[actionPath] = require('partner/actions/get-fatoorah-payment-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-inquery-options.action';
            binding.dataActions[actionPath] = require('partner/actions/get-inquery-options.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-my-fatoorah-payment-methods.action';
            binding.dataActions[actionPath] = require('partner/actions/get-my-fatoorah-payment-methods.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-payment-access-token.action';
            binding.dataActions[actionPath] = require('partner/actions/get-payment-access-token.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-payment-status.action';
            binding.dataActions[actionPath] = require('partner/actions/get-payment-status.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-sharepoint-token.action';
            binding.dataActions[actionPath] = require('partner/actions/get-sharepoint-token.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/post-business-op-case.action';
            binding.dataActions[actionPath] = require('partner/actions/post-business-op-case.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/post-checkout-data-to-crm.action';
            binding.dataActions[actionPath] = require('partner/actions/post-checkout-data-to-crm.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/post-contact-us-form.action';
            binding.dataActions[actionPath] = require('partner/actions/post-contact-us-form.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/post-gates.action';
            binding.dataActions[actionPath] = require('partner/actions/post-gates.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/post-new-contact.action';
            binding.dataActions[actionPath] = require('partner/actions/post-new-contact.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/post-subscribe.action';
            binding.dataActions[actionPath] = require('partner/actions/post-subscribe.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/post-tefanawwal-form.action';
            binding.dataActions[actionPath] = require('partner/actions/post-tefanawwal-form.action');
            registerActionId(actionPath);
        }

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.packageDataActions = {};
        window.__bindings__.packageDataActions['__local__'] = {
            ...binding.dataActions
        };